import Select from "@mui/material/Select";
import dayjs from "dayjs";
import { CalendarMonth } from "@mui/icons-material";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
import { Locked } from "../../templates/Locked";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Alert from "@mui/material/Alert";

import { useAuth } from "../../../hooks/useAuth";
import { useMutation, useQuery } from "@tanstack/react-query";
import { alertPatient as alertPatientApi, getPatientTypes as getPatientTypesApi } from "../../../services/routes";

export const Dashboard = () => {
  const { me, token } = useAuth();
  const [city, setCity] = useState(me?.user_cities[0].city_code);
  const [patientType, setPatientType] = useState('');

  const { mutate: alertPregnants, isPending, isError, isSuccess } = useMutation({
    mutationFn: () => alertPatientApi({ cityId: city, token, patientType }),
  });

  const {data:patientTypes,isLoading} = useQuery({
    queryFn: () => getPatientTypesApi(token),
    queryKey:[token]
  })

  return (
    <Locked title="Disparo de mensagens">
      <Stack gap="16px">
        <Typography fontWeight={"bold"}>Enviar por</Typography>
        <Stack ml={1.5}>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={<Checkbox name="whatsapp" checked={true} />}
              label={<Typography>Whatsapp</Typography>}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox name="telegram" checked={false} disabled={true} />
              }
              label={<Typography>Telegram</Typography>}
            />
          </Stack>
          <Stack direction="row" alignItems="center">
            <FormControlLabel
              control={<Checkbox name="sms" checked={false} disabled={true} />}
              label={<Typography>Telefone</Typography>}
            />
          </Stack>
        </Stack>
      </Stack>
      <Stack gap="16px">
        <Typography fontWeight={"bold"}>Cidade</Typography>
        <FormControl>
          <Select
            sx={{ backgroundColor: "primary.dark" }}
            value={city}
            onChange={(e) => {
              setCity(e.target.value);
            }}
          >
            <MenuItem selected value="">
              Escolha uma cidade
            </MenuItem>
            {me?.user_cities.map(({ city_code, city_name }) => (
              <MenuItem value={city_code}>{city_name}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack gap="16px">
        <Typography fontWeight={"bold"}>Enviar para</Typography>
        <FormControl>
          <Select
            sx={{ backgroundColor: "primary.dark" }}
            value={patientType}
            onChange={(value) => setPatientType(value.target.value)}
          >
            {isLoading ? <MenuItem value="" selected>
              Carregando
            </MenuItem>: null}
            {patientTypes?.data.map(({key,label},i)=>(
              <MenuItem key={i} value={key}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>
      <Stack gap="16px">
        <Typography fontWeight={"bold"}>Quando</Typography>
        <Stack flexDirection={"row"} alignItems={"center"} gap={2}>
          <CalendarMonth />
          <DateTimePicker
            format="DD/MM/YYYY HH:mm"
            label={dayjs().format("DD/MM/YYYY HH:mm")}
            value={dayjs()}
            // onChange={(date) => date != null && handleChangeDateTime(date)}
            sx={{ backgroundColor: "primary.dark" }}
          />
        </Stack>
      </Stack>

      {isError && (
        <Alert severity="error" variant="filled">
          Erro ao enviar mensagem!
        </Alert>
      )}
      {isSuccess && (
        <Alert severity="success" variant="filled">
          Mensagens enviadas com sucesso
        </Alert>
      )}
      <Button variant="contained" onClick={(e) => alertPregnants()} disabled={isPending}>
        Enviar mensagem
      </Button>
    </Locked>
  );
};
