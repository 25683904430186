import { Container, Stack } from "@mui/material";

export const UnlockedTemplate: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <Container>
      <Stack
        height="100vh"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        gap="36px"
        padding="16px"
      >
        {children}
      </Stack>
    </Container>
  );
};
