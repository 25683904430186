import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";

import "@fontsource/inter";
export const colors = {
  10: "#141B41",
};
const theme = createTheme({
  palette: {
    primary: {
      main: "#141B41",
      dark: "#F8F8F8",
      light: "#FFF",
      "900": "#141B41",
    },
  },
  typography: {
    fontFamily: '"Inter", sans-serif',
  },
});

export const Theme = ({ children }: { children: React.ReactNode }) => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};
