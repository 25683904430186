import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Router } from "./Router";
import { Theme } from "./Theme";
import { AuthProvider } from "./providers/AuthProvider";
import { LoginProvider } from "./providers/LoginProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

const queryClient = new QueryClient();

export const App = () => {
  return (
    <Theme>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <LoginProvider>
              <Router />
            </LoginProvider>
          </AuthProvider>
        </QueryClientProvider>
      </LocalizationProvider>
    </Theme>
  );
};
