import { Image } from "@mui/icons-material";
import { Button, Container, Stack, Typography } from "@mui/material";
import logo from "../../../assets/images/cbaityhy-curta-branca 1.png";
import avatar from "../../../assets/images/avatar.png";
import { useAuth } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

export const Locked = ({
  children,
  title,
}: {
  children: React.ReactNode;
  title: string;
}) => {
  // const {medicalData} = useMedicalData()

  const { logout, me } = useAuth();
  const navigate = useNavigate();
  return (
    <Container>
      <Stack gap="36px" padding="16px">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <img src={logo} style={{ width: 60, height: 60 }} />
          <Stack direction="row" gap="16px">
            <Stack alignItems="end" justifyContent={"end"}>
              <Typography fontWeight={"bold"} fontSize={14}>
                {me?.name}
              </Typography>
              <Typography fontSize={14}>{me?.email}</Typography>
            </Stack>
            <img src={avatar} style={{ width: 60, height: 60 }} />
          </Stack>
        </Stack>
        <Stack alignItems="end" justifyContent={"end"}>
          <Button
            sx={{
              width: "22px",
              height: "30px",
              gap: "10px",
              textTransform: "none",
              fontSize: "15px",
              fontWeight: "bold",
            }}
            onClick={() => {
              logout();
              navigate(0);
            }}
          >
            Sair
          </Button>
        </Stack>
        <Typography fontWeight={"bold"} fontSize={20}>
          {title}
        </Typography>
        {children}
      </Stack>
    </Container>
  );
};
