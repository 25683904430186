import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Dashboard } from "./components/pages/Dashboard";
import { useAuth } from "./hooks/useAuth";
import { Login } from "./components/pages/Login";

export const Router = () => {
  const { token } = useAuth();

  const privateRoutes = [{ path: "/", element: <Dashboard /> }];
  const publicRoutes = [{ path: "*", element: <Login /> }];

  const router = createBrowserRouter(token ? privateRoutes : publicRoutes);

  return <RouterProvider router={router} />;
};
