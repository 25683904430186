import { createContext } from "react";
import { IFnLoginParams } from "../services/routes";
import { UseMutateFunction } from "@tanstack/react-query";
import { AxiosResponse } from "axios";

export interface ILoginContext {
  sendAccessToken: UseMutateFunction<AxiosResponse<null, any>, Error, string, unknown>;
  sendEmailAccessToken: UseMutateFunction<string, Error, IFnLoginParams, unknown>;
  token?: string;
  isSendingAccessKey: boolean;
  accessKeySended: boolean;
  isRecievingToken: boolean;
  email?: string;
  setEmail: (email: string) => void;
}

export const LoginContext = createContext<ILoginContext>({
  sendAccessToken: () => {},
  isRecievingToken: false,
  isSendingAccessKey: false,
  setEmail: () => {},
  accessKeySended: false,
  sendEmailAccessToken: () => {},
});
