import { createContext } from "react";
import { IUser } from "../services/routes";

export interface IAuthContext {
  token: string | null;
  logout: Function;
  updateToken: Function;
  me?: IUser;
}

export const AuthContext = createContext<IAuthContext>({
  logout: () => {},
  updateToken: () => {},
  token: null,
});
