import { axiosInstance } from ".";

export interface IFnLoginParams {
  email: string;
  accessKey: string;
}
export const sendToken = (data: IFnLoginParams) => {
  return axiosInstance.post<{ token: string }>("/auth/send-mail/token", data);
};

export const sendAccessKey = (email: string) => {
  return axiosInstance.post<null>("/auth/send-mail/chave-de-acesso", { email });
};

export interface IUser {
  token: string;
  login: string;
  name: string;
  email: string;
  active: string;
  user_cities: UserCity[];
}

export interface UserCity {
  city_code: any;
  city_name: any;
}

export const getMyInfos = (token: string) => {
  return axiosInstance.get<IUser>("/auth/me", {
    headers: {
      customcbatoken: token,
    },
  });
};

export const alertPatient = ({
  cityId,
  token,
  patientType
}: {
  cityId: string;
  patientType: string;
  token: string | null;
}) => {
  return axiosInstance.post(`/alert/${patientType}/${cityId}`, null, {
    headers: {
      customcbatoken: token,
    },
  });
};

export const getPatientTypes = (token:string|null) => {
  return axiosInstance.get<{key:string;label:string}[]>(`/alert/patientTypes`, {
    headers: {
      customcbatoken: token,
    },
  });
}
