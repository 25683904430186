import { Box, Button, Stack, TextField, useTheme } from "@mui/material";
import { useLogin } from "../../../hooks/useLogin";
import { UnlockedTemplate } from "../../templates/Unlocked";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";

import logo from "../../../assets/images/cbaityhy-curta-branca 1.png";
import { AlertOrganism } from "../../organism/Alert";
import { useEffect } from "react";

export const Login = () => {
  const {
    isSendingAccessKey,
    email,
    setEmail,
    sendEmailAccessToken,
    accessKeySended,
    sendAccessToken,
    isRecievingToken,
    token,
  } = useLogin();

  useEffect(() => {
    if (token) {
      navigate(0);
    }
  }, [token]);

  const loginV1Schema = Yup.object({
    email: Yup.string().email().required(),
    accessKey: Yup.string(),
  });
  const formik = useFormik({
    onSubmit: (values) => {
      sendEmailAccessToken(values);
    },
    initialValues: {
      email: "",
      accessKey: "",
    },
    validationSchema: loginV1Schema,
  });
  const navigate = useNavigate();
  return (
    <UnlockedTemplate>
      {accessKeySended ? (
        <AlertOrganism
          variant="filled"
          severity="info"
          msg={`A chave de acesso foi enviada para o email ${email}`}
        />
      ) : null}
      <Box
        component="img"
        width="120px"
        height="120px"
        src={logo}
        alt="CBA Logo"
      />
      <form
        onSubmit={(e) => {
          e.preventDefault();
          formik.submitForm();
        }}
      >
        <Stack gap="16px">
          <TextField
            placeholder="E-mail"
            type="email"
            name="email"
            variant="filled"
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched && !!formik.errors.email}
            disabled={accessKeySended}
          />
          {accessKeySended ? (
            <>
              <TextField
                type="text"
                name="accessKey"
                label="Chave de acesso"
                variant="filled"
                value={formik.values.accessKey}
                onChange={formik.handleChange}
                error={formik.touched && !!formik.errors.accessKey}
              />
              <Button
                sx={{ textTransform: "none", height: "44px", gap: "10px" }}
                onClick={() => {
                  navigate(0);
                }}
              >
                Reenviar chave de acesso
              </Button>
            </>
          ) : null}
        </Stack>
        <Stack paddingTop={accessKeySended ? "36px" : "16px"}>
          <Button
            color="primary"
            sx={{
              width: "328px",
              height: "46px",
              fontSize: "16px",
              textTransform: "none",
            }}
            type={accessKeySended ? "submit" : "button"}
            onClick={() => {
              if (!accessKeySended) {
                const email = formik.values.email;
                setEmail(email);
                sendAccessToken(email);
              }
            }}
            variant="contained"
            disabled={isSendingAccessKey || isRecievingToken}
          >
            {accessKeySended ? "Confirmar" : "Prosseguir"}
          </Button>
        </Stack>
      </form>
    </UnlockedTemplate>
  );
};
