import { useEffect } from "react";
import { AuthContext } from "../context/AuthContext";
import { useMutation } from "@tanstack/react-query";
import { getMyInfos } from "../services/routes";
import { useNavigate } from "react-router-dom";

const STORAGE_KEY = encodeURI("auth_token");
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const {
    mutate: getMyInfoFn,
    data: me,
    isPending,
    isError,
  } = useMutation({
    mutationFn: async (token: string) => {
      const retrievedInfos = await getMyInfos(token);
      return retrievedInfos.data;
    },
  });

  useEffect(() => {
    const token = localStorage.getItem(STORAGE_KEY);
    if (token) {
      getMyInfoFn(token);
    }
    if (isError) {
      logout();
    }
  }, [isError]);

  const updateToken = (token: string) => {
    localStorage.setItem(STORAGE_KEY, token);
  };

  const logout = () => {
    localStorage.removeItem(STORAGE_KEY);
  };

  return (
    <AuthContext.Provider
      value={{
        token: localStorage.getItem(STORAGE_KEY),
        logout,
        updateToken,
        me,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
