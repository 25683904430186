import { Alert, AlertProps, Stack } from "@mui/material";

export const AlertOrganism: React.FC<
  {
    msg: string;
  } & AlertProps
> = ({ msg, severity, variant }) => {
  return (
    <Stack padding="16px">
      <Alert variant={variant} severity={severity}>
        {msg}
      </Alert>
    </Stack>
  );
};
