import { useState } from "react";
import { LoginContext } from "../context/LoginContext";
import { useMutation } from "@tanstack/react-query";
import { IFnLoginParams, sendAccessKey, sendToken } from "../services/routes";
import { useAuth } from "../hooks/useAuth";

export const LoginProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [email, setEmail] = useState<string>("");
  const { updateToken } = useAuth();
  const {
    mutate: sendAccessToken,
    isPending: isSendingAccessKey,
    isSuccess: accessKeySended,
    isError: accessKeyError,
  } = useMutation({
    mutationFn: sendAccessKey,
  });

  const {
    mutate: sendEmailAccessToken,
    isPending: isRecievingToken,
    data: token,
    isSuccess: isEmailAccessSuccess,
    isError: isEmailAccessError,
  } = useMutation({
    mutationFn: async (data: IFnLoginParams) => {
      const responseToken = await sendToken(data);
      const token = responseToken.data.token;
      updateToken(token);
      return token;
    },
  });

  return (
    <LoginContext.Provider
      value={{
        accessKeySended,
        isSendingAccessKey,
        sendEmailAccessToken,
        sendAccessToken,
        setEmail,
        email,
        isRecievingToken,
        token,
      }}
    >
      {children}
    </LoginContext.Provider>
  );
};
